import React from 'react'
import { get, capitalize } from 'lodash'
import { fullDateTimeFormat } from '../../../common/utils/dates'
import { StyledTableRow } from '../../../common/theme/components/tables/StyledTableRow'
import CheckmarkCell from '../../atoms/Cells/CheckmarkCell'
import { StyledTableCell } from '../../../common/theme/components/tables/StyledTableCell'
import { keyframes } from '@mui/system'

const fadeC = keyframes`
    from {
        background-color: #85afc9;
    }
`
const SensorEventListRow = ({
  sensorEvent,
  hasTemperature,
  hasPowerEvent,
  isSuperadmin,
  showSensorInfo = true
}) => {
  const sensor = get(sensorEvent, ['sensor'], {})

  return (
    <StyledTableRow
      hover
      key={sensorEvent.id}
      sx={
        sensorEvent.isNew
          ? { animation: `${fadeC} ease`, animationIterationCount: 1, animationDuration: '5s' }
          : ''
      }
    >
      {/* If all rows for the same sensor, we don't show the sensor information */}
      {showSensorInfo && (
        <>
          <StyledTableCell align='left'>{sensor.unit?.number}</StyledTableCell>
          <StyledTableCell align='left'>{sensor.location}</StyledTableCell>
        </>
      )}
      <StyledTableCell align='left'>{sensorEvent.displayEventType}</StyledTableCell>
      <StyledTableCell align='left'>{fullDateTimeFormat(sensorEvent.eventTime)}</StyledTableCell>
      {hasTemperature && <StyledTableCell align='left'>{sensorEvent.temperature}</StyledTableCell>}
      {hasPowerEvent && <StyledTableCell align='left'>{capitalize(sensorEvent.powerSource)}</StyledTableCell>}
      <CheckmarkCell booleanValue={sensorEvent.generatedAlert} />
      <CheckmarkCell booleanValue={sensorEvent.sensorMuted} />
      {isSuperadmin && <StyledTableCell align='left'>{sensor.hardwareId}</StyledTableCell>}
      {isSuperadmin && <StyledTableCell align='left'>{sensorEvent.messageId}</StyledTableCell>}
    </StyledTableRow>
  )
}

export default SensorEventListRow
