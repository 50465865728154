import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SensorFragment = { __typename?: 'Sensor', id: string, hardwareId?: string | null, buildingId?: string | null, unitId?: string | null, deviceName?: string | null, location?: string | null, inUse: boolean, enableAlerts: boolean, monitorWater: boolean, monitorTemperature: boolean, monitorHumidity: boolean, monitorDoor: boolean, monitorPower: boolean, waterSensitivity?: number | null, lowTemperatureThreshold?: number | null, highTemperatureThreshold?: number | null, lowHumidityThreshold?: number | null, highHumidityThreshold?: number | null };

export type CreateSensorMutationVariables = Types.Exact<{
  input: Types.CreateSensorInput;
}>;


export type CreateSensorMutation = { __typename?: 'Mutation', createSensor: { __typename?: 'Sensor', id: string, hardwareId?: string | null, buildingId?: string | null, unitId?: string | null, deviceName?: string | null, location?: string | null, inUse: boolean, enableAlerts: boolean, monitorWater: boolean, monitorTemperature: boolean, monitorHumidity: boolean, monitorDoor: boolean, monitorPower: boolean, waterSensitivity?: number | null, lowTemperatureThreshold?: number | null, highTemperatureThreshold?: number | null, lowHumidityThreshold?: number | null, highHumidityThreshold?: number | null } };

export const SensorFragmentDoc = gql`
    fragment Sensor on Sensor {
  id
  hardwareId
  buildingId
  unitId
  deviceName
  location
  inUse
  enableAlerts
  monitorWater
  monitorTemperature
  monitorHumidity
  monitorDoor
  monitorPower
  waterSensitivity
  lowTemperatureThreshold
  highTemperatureThreshold
  lowHumidityThreshold
  highHumidityThreshold
}
    `;
export const CreateSensorDocument = gql`
    mutation CreateSensor($input: CreateSensorInput!) {
  createSensor(input: $input) {
    ...Sensor
  }
}
    ${SensorFragmentDoc}`;
export type CreateSensorMutationFn = Apollo.MutationFunction<CreateSensorMutation, CreateSensorMutationVariables>;

/**
 * __useCreateSensorMutation__
 *
 * To run a mutation, you first call `useCreateSensorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSensorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSensorMutation, { data, loading, error }] = useCreateSensorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSensorMutation(baseOptions?: Apollo.MutationHookOptions<CreateSensorMutation, CreateSensorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSensorMutation, CreateSensorMutationVariables>(CreateSensorDocument, options);
      }
export type CreateSensorMutationHookResult = ReturnType<typeof useCreateSensorMutation>;
export type CreateSensorMutationResult = Apollo.MutationResult<CreateSensorMutation>;
export type CreateSensorMutationOptions = Apollo.BaseMutationOptions<CreateSensorMutation, CreateSensorMutationVariables>;