import { get, isEmpty, intersection, map } from 'lodash'

import { useFetchSensorEventsTableQuery } from './GraphQl/gql.generated'
import { EVENT_TYPE_POWER } from '../../../common/constants/alerts'
import { isTypeSuperadmin } from '../../../common/utils/users'

const useSensorEventsTable = ({ sensorId, page, rowsPerPage, userType }) => {
  const isSuperadmin = isTypeSuperadmin(userType)

  const {
    data,
    loading,
    refetch: refetchSensorEvents
  } = useFetchSensorEventsTableQuery({
    variables: {
      sensorId,
      pagination: {
        page: page + 1, // TablePagination starts count pages from zero / 0
        per: rowsPerPage
      }
    }
  })

  const sensorEvents = get(data, ['fetchSensorEvents', 'collection'], [])
  const metadata = get(data, ['fetchSensorEvents', 'metadata'], [])

  const hasTemperature = sensorEvents.some((sensorEvent) => sensorEvent.temperature)
  const hasPowerEvent = !isEmpty(intersection(map(sensorEvents, 'eventType'), [EVENT_TYPE_POWER]))

  return {
    loading,
    sensorEvents,
    metadata,
    hasTemperature,
    hasPowerEvent,
    isSuperadmin,
    refetchSensorEvents
  }
}

export default useSensorEventsTable
