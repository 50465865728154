import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material'

import { NUMBER_OF_ROWS_PER_PAGE_TEN } from '../../../common/constants/pagination'

import Card from '../../molecules/Card'
import AlertsTable from '../../organisms/AlertsTable'
import SensorShowInformation from '../../organisms/SensorShowInformation'
import SensorEventsTable from '../../organisms/SensorEventsTable/SensorEventsTable'

import useSensorPage from './useSensorPage'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useLocation } from 'react-router-dom'

const SensorPage = () => {
  const { loading, userType, sensor, building, unit, sensorLocationPhoto } = useSensorPage()
  const useQuery = new URLSearchParams(useLocation().search)
  const collapseDetails = useQuery.get('collapse_details') === 'true'
  console.log(useQuery.get('collapse_details'))
  console.log(collapseDetails)
  const [isExpanded, setIsExpanded] = useState(!collapseDetails)
  const handleAccordionChange = (event, newExpandedState) => setIsExpanded(newExpandedState)

  const tableContainerFixedHeight = '600px' // height of tables "parent" card

  // height of the actual table relative to the "parent" card's height (tableContainerFixedHeight)
  const innerTableRelativeHeightAlerts = '80%' // only 80% to account for the event type filter
  const innerTableRelativeHeightSensorEvents = '90%'

  if (loading) return <React.Fragment></React.Fragment>
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={3}>
          <Accordion expanded={isExpanded} onChange={handleAccordionChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon>Hide</ExpandMoreIcon>}>
              <Typography variant='h6' sx={{ width: '100%', textAlign: 'center' }}>
                Sensor Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* Left side of page */}
              <SensorShowInformation
                sensor={sensor}
                sensorLocationPhoto={sensorLocationPhoto}
                building={building}
                unit={unit}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
        {/* Right side of page */}
        <Grid item xs={12} lg={9}>
          <Card
            title={'Sensor Events'}
            maxWidth={false}
            containerSx={{ height: tableContainerFixedHeight }}
            gridSx={{ height: innerTableRelativeHeightSensorEvents }}
          >
            <SensorEventsTable
              enableAutoRefresh={false}
              sensor={sensor}
              building={building}
              userType={userType}
              numRowsPerPage={NUMBER_OF_ROWS_PER_PAGE_TEN}
              tableContainerSx={{
                height: '100%' /* ensures table takes up 100% of innerTableRelativeHeight */
              }}
              tableSx={{
                height: '90%' /* actual table height. Need to ensure room for pagination buttons */
              }}
            />
          </Card>
          <br />

          <Card
            title={'Alerts'}
            maxWidth={false}
            containerSx={{ height: tableContainerFixedHeight }}
            gridSx={{ height: innerTableRelativeHeightAlerts }}
          >
            <AlertsTable
              building={building}
              sensor={sensor}
              showEventTypeFilter={true}
              numRowsPerPage={NUMBER_OF_ROWS_PER_PAGE_TEN}
              tableContainerSx={{
                height: '100%' /* ensures table takes up 100% of innerTableRelativeHeight */
              }}
              tableSx={{
                height: '90%' /* actual table height. Need to ensure room for pagination buttons */
              }}
            />
          </Card>
          <br />
        </Grid>
      </Grid>
    </>
  )
}

export default SensorPage
