import { get, isNil } from 'lodash'
import { getDaysFromArrayString } from './dates'

export const getSensorEventBatteryPercentage = (sensorEvent) => {
  if (!sensorEvent) return

  // Some sensors report battery percentage, while others return voltage. If no percentage is found, then we estimate percentage based on the voltage.
  if (sensorEvent.batteryPercentage) {
    return sensorEvent.batteryPercentage
  }

  if (!sensorEvent.battery) return
  if (sensorEvent.battery >= 3) return 100
  if (sensorEvent.battery < 2.5) return 20

  // 3.00 volts means roughly 100%, 2.5 means battery is dead (or almost dead)
  // We estimate percentage remaining by subtracting the battery level from 3 and inverting that result against 100%
  // eg. battery reported at 2.9v should show 80%
  return parseInt((50 - (3 - sensorEvent.battery) * 100) * 2)
}

export const getBatteryIcon = (percentage) => {
  if (!percentage) return

  if (percentage >= 85) {
    return 'fa-battery-full'
  } else if (percentage < 85 && percentage >= 70) {
    return 'fa-battery-three-quarters'
  } else if (percentage < 70 && percentage >= 40) {
    return 'fa-battery-half'
  } else if (percentage < 40 && percentage >= 20) {
    return 'fa-battery-quarter'
  } else {
    return 'fa-battery-empty'
  }
}

export const getSensorTemperatureRangeString = (sensor) => {
  const lowTemperatureThreshold = get(sensor, ['lowTemperatureThreshold'], null)
  const highTemperatureThreshold = get(sensor, ['highTemperatureThreshold'], null)
  if (isNil(lowTemperatureThreshold) || isNil(highTemperatureThreshold)) return ''
  return `${lowTemperatureThreshold}° to ${highTemperatureThreshold}°`
}

export const getSensorHumidityRangeString = (sensor) => {
  const lowHumidityThreshold = get(sensor, ['lowHumidityThreshold'], null)
  const highHumidityThreshold = get(sensor, ['highHumidityThreshold'], null)
  if (isNil(lowHumidityThreshold) || isNil(highHumidityThreshold)) return ''
  return `${lowHumidityThreshold}% to ${highHumidityThreshold}%`
}

export const getSensorDoorAlertingDaysString = (sensor) => {
  const sensorDoorAlertDays = get(sensor, ['doorAlertDays'], null)
  if (!sensorDoorAlertDays) return ''
  return getDaysFromArrayString(sensorDoorAlertDays)
}

export const getSensorDoorAlertingHoursString = (sensor) => {
  const sensorDoorAlertStartTime = get(sensor, ['doorAlertStartTime'], null)
  const sensorDoorAlertEndTime = get(sensor, ['doorAlertEndTime'], null)
  if (isNil(sensorDoorAlertStartTime) || isNil(sensorDoorAlertEndTime)) return ''
  return `${sensorDoorAlertStartTime} until ${sensorDoorAlertEndTime}`
}
