import React, { Fragment, useEffect, useRef, useState } from 'react'
import { isEmpty, isNil } from 'lodash'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableCell,
  Box,
  CircularProgress,
  Typography
} from '@mui/material'

import { unitLabel } from '../../../common/utils/buildings'
import usePagination from '../../../common/hooks/usePagination'
import { StyledTableCell } from '../../../common/theme/components/tables/StyledTableCell'
import { SENSOR_EVENTS_ALERT_GENERATED, SENSOR_EVENTS_SENSOR_MUTED } from '../../../common/constants/tooltips'
import useSensorEventsTable from './useSensorEventsTable'

import SensorEventListRow from '../../molecules/SensorEventListRow'
import { StyledTableRow } from '../../../common/theme/components/tables/StyledTableRow'
import MouseOverPopover from '../../molecules/MouseOverPopover'

const SensorEventsTable = ({
  sensor = null,
  building,
  userType,
  numRowsPerPage,
  tableContainerSx = {},
  tableSx = {},
  showNoRecordsMessage = true,
  enableAutoRefresh = false
}) => {
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, rowsPerPageOptions } = usePagination({
    numRowsPerPage
  })

  const {
    loading,
    sensorEvents,
    metadata,
    hasTemperature,
    hasPowerEvent,
    isSuperadmin,
    refetchSensorEvents
  } = useSensorEventsTable({
    sensorId: sensor?.id,
    page,
    rowsPerPage,
    userType
  })

  const [currentSensorEvents, setCurrentSensorEvents] = useState(sensorEvents)

  if (enableAutoRefresh) {
    useEffect(() => {
      const interval = setInterval(() => {
        refetchSensorEvents().then(({ data }) => {
          const newData = data?.fetchSensorEvents?.collection
          // Make sure we have both current and previous data
          if (newData && currentSensorEvents) {
            const updatedData = newData.map((row, index) => {
              // Check if this row exists in previous data
              const existsInOld = currentSensorEvents.some((oldRow) => oldRow.id === row.id)
              return { ...row, isNew: !existsInOld }
            })
            setCurrentSensorEvents(updatedData)
          }
        })
      }, 5000)

      return () => clearInterval(interval) // Cleanup on unmount
    }, [refetchSensorEvents, currentSensorEvents])
  }

  useEffect(() => {
    setCurrentSensorEvents(sensorEvents)
  }, [loading])

  // if (loading) return <></>
  const numberOfColumns = 10 // used for "No Sensor Events Recorded" message
  return (
    <TableContainer sx={tableContainerSx}>
      <Table sx={tableSx}>
        <TableHead>
          <TableRow>
            {/* Check if a sensor was passed in or not, if all rows for the same sensor, we don't show the sensor information. */}
            {isNil(sensor) && (
              <>
                <StyledTableCell align='left'>Sensor {unitLabel(building)}</StyledTableCell>
                <StyledTableCell align='left'>Sensor Location</StyledTableCell>
              </>
            )}
            <StyledTableCell align='left'>Event Type</StyledTableCell>
            <StyledTableCell align='left'>Event Time</StyledTableCell>
            {hasTemperature && <StyledTableCell align='left'>Temperature °C</StyledTableCell>}
            {hasPowerEvent && <StyledTableCell align='left'>Power Source</StyledTableCell>}
            <StyledTableCell align='left' sx={{ width: '5%' }}>
              <MouseOverPopover baseText=' Alert Generated'>
                <Box sx={{ border: '2px solid #365970', p: 1, maxWidth: '300px' }}>
                  {SENSOR_EVENTS_ALERT_GENERATED}
                </Box>
              </MouseOverPopover>
            </StyledTableCell>

            <StyledTableCell align='left' sx={{ width: '5%' }}>
              <MouseOverPopover baseText='Sensor Muted'>
                <Box sx={{ border: '2px solid #365970', p: 1, textAlign: 'center' }}>
                  {SENSOR_EVENTS_SENSOR_MUTED}
                </Box>
              </MouseOverPopover>
            </StyledTableCell>
            {isSuperadmin && <StyledTableCell align='left'>Hardware ID</StyledTableCell>}
            {isSuperadmin && <StyledTableCell align='left'>Message ID</StyledTableCell>}
          </TableRow>
        </TableHead>
        <TableBody className='tight-table-row'>
          {loading ? (
            <StyledTableRow hover>
              <TableCell align='center' sx={{ verticalAlign: 'top' }} colSpan={numberOfColumns}>
                <CircularProgress sx={{ mt: 5 }} />
              </TableCell>
            </StyledTableRow>
          ) : (
            <>
              {showNoRecordsMessage && isEmpty(sensorEvents) && (
                <StyledTableRow hover>
                  <TableCell align='center' sx={{ verticalAlign: 'top' }} colSpan={numberOfColumns}>
                    <Typography component={'h4'} sx={{ mt: 3 }}>
                      No Sensor Events Recorded
                    </Typography>
                  </TableCell>
                </StyledTableRow>
              )}
              {currentSensorEvents.map((sensorEvent) => (
                <Fragment key={sensorEvent.id}>
                  <SensorEventListRow
                    sensorEvent={sensorEvent}
                    hasTemperature={hasTemperature}
                    hasPowerEvent={hasPowerEvent}
                    isSuperadmin={isSuperadmin}
                    showSensorInfo={isNil(sensor)}
                  />
                </Fragment>
              ))}
            </>
          )}
        </TableBody>
      </Table>
      <TablePagination
        colSpan={4}
        rowsPerPageOptions={rowsPerPageOptions}
        component='div'
        count={metadata?.totalCount || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}

export default SensorEventsTable
