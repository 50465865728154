import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Scanner } from '@yudiel/react-qr-scanner'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  FormHelperText,
  useTheme,
  useMediaQuery
} from '@mui/material'
import { get, isEmpty } from 'lodash'
import { useGetCurrentUserQuery } from '../dashboards/DashboardsList/gql.generated'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import CreateUnitModal from '../../organisms/Units/CreateUnitModal'
import { useFetchUnitsLazyQuery } from '../dashboards/components/DashboardFiltersPanel/gql.generated'
import { getOrdinal, sensorDisplay } from '../../../common/utils/display'
import { useCreateSensorMutation } from './GraphQl/gql.generated'
import { useGetSensorsPageLazyQuery } from '../SensorPage/GraphQl/gql.generated'
import FormCheckbox from '../../atoms/CheckmarkCell'
import TooltipIcon from '../../atoms/TooltopIcon'
import ConfigSlider from '../../molecules/Sliders/ConfigSlider'
import {
  WATER_SENSITIVITY_SLIDER_MARKS,
  TEMPERATURE_SLIDER_MARKS,
  HUMIDITY_SLIDER_MARKS
} from '../../../common/constants/sensors'

const SensorCreatePage = () => {
  const [qrModalOpen, setQrModalOpen] = useState(false)
  const [showUnitModal, setShowUnitModal] = useState(false)
  const [submitError, setSubmitError] = useState('')
  const [scanWarning, setScanWarning] = useState('')
  const [units, setUnits] = useState([])
  const [tempRange, setTempRange] = React.useState([5, 45])
  const [humidityRange, setHumidityRange] = React.useState([0, 100])
  const [waterSensitivity, setWaterSensitivity] = React.useState(0)
  const useQuery = new URLSearchParams(useLocation().search)
  const buildingId = useQuery.get('building_id')

  const { data: userData, loading: userLoading } = useGetCurrentUserQuery()
  const [getSensor, { data, loading }] = useGetSensorsPageLazyQuery()
  const [getUnits, { data: unitsData, loading: unitsLoading }] = useFetchUnitsLazyQuery({
    variables: {
      buildingIds: [buildingId],
      pagination: {
        per: 300
      }
    }
  })
  //
  const schema = yup
    .object({
      hardwareId: yup.string().required('Hardware ID is a required field'),
      deviceName: yup.string().required('Make/Model is a required field'),
      location: yup.string().required('Location is a required field'),
      unitId: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required('Zone is required')
    })
    .required()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      hardwareId: '',
      deviceName: '',
      location: '',
      unitId: '',
      inUse: true,
      enableAlerts: false,
      monitorWater: false,
      monitorTemperature: false,
      monitorHumidity: false,
      monitorDoor: false,
      monitorPower: false,
      waterSensitivity: null,
      lowTemperatureThreshold: null,
      highTemperatureThreshold: null,
      lowHumidityThreshold: null,
      highHumidityThreshold: null
    }
  })

  const hardwareId = watch('hardwareId', '')
  const inUseEnabled = watch('inUse', false)
  const tempEnabled = watch('monitorTemperature', false)
  const humidityEnabled = watch('monitorHumidity', false)
  const waterEnabled = watch('monitorWater', false)

  const handleAddUnit = (newUnit) => {
    setUnits([...units, newUnit])
    setValue('unitId', newUnit.id, { shouldValidate: true })
    setShowUnitModal(false)
  }

  useEffect(() => {
    const user = userData?.getCurrentUser
    if (!user?.userBuildings.some((ub) => ub.building.id === buildingId)) {
    }
    getUnits().then((responseData) => setUnits(get(responseData, ['data', 'fetchUnits'], [])))

    //   Temporary set to skip QR code scanning while building form
    setValue('hardwareId', 'abc123abc123', { shouldValidate: true })
    setValue('deviceName', 'Tektelic Comfort V2', { shouldValidate: true })
  }, [userLoading])

  const handleClose = ({ scanResult = [] }) => {
    setScanWarning('')
    if (!isEmpty(scanResult)) {
      getSensor({
        variables: {
          hardwareId: scanResult[0].rawValue
        },
        onCompleted: (data) => {
          const sensor = get(data, ['getSensor'], {})
          if (!isEmpty(sensor)) {
            // If sensor already exists we let the user know they would be moving it to another location/building
            if (!isEmpty(sensor.building)) {
              setScanWarning(
                'Sensor already exists, if you continue you will be moving this sensor to your' +
                  'new deployment location. Please ensure you want to move this sensor before proceeding. The sensor is' +
                  'currently located at: ' +
                  sensorDisplay(sensor)
              )
            }
            // setHardwareId()
            setValue('hardwareId', sensor.hardwareId, { shouldValidate: true })
            setValue('deviceName', sensor.deviceName, { shouldValidate: true })
          }
        },
        onError: (error) => setSubmitError(error.message)
      })
    }
    setQrModalOpen(false)
  }

  const [createSensor] = useCreateSensorMutation()
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.down('sm'))

  const handleSensorCreateSubmit = (input) => {
    setSubmitError('')
    const submitInput = { ...input, ...{ buildingId: buildingId } }

    if (waterEnabled) {
      submitInput.waterSensitivity = waterSensitivity
    }
    if (tempEnabled) {
      submitInput.lowTemperatureThreshold = tempRange[0]
      submitInput.highTemperatureThreshold = tempRange[1]
    }
    if (humidityEnabled) {
      submitInput.lowHumidityThreshold = humidityRange[0]
      submitInput.highHumidityThreshold = humidityRange[1]
    }

    createSensor({
      variables: {
        input: submitInput
      },
      onCompleted: (data) => {
        const createdSensor = get(data, ['createSensor'], {})
        window.location.href = `/sensors/${
          createdSensor.id
        }?collapse_details=${isXs}&building_id=${buildingId.toString()}`
      },
      onError: (error) => setSubmitError(error.message)
    })
  }

  return (
    <>
      <Box sx={{ m: 'auto', pt: 2, textAlign: 'center' }}>
        <Typography variant='h5' component='h5' sx={{ pb: 2 }}>
          Add New Sensor
        </Typography>
        {submitError !== '' && (
          <p className='error-text'>
            {submitError}
            <br />
            <br />
          </p>
        )}
        {scanWarning !== '' && (
          <Box component='p' sx={{ m: 'auto', textAlign: 'center', maxWidth: '500px' }}>
            <p className='warning-text'>
              {scanWarning}
              <br />
              <br />
            </p>
          </Box>
        )}
        {hardwareId === '' && (
          <Button variant='contained' onClick={() => setQrModalOpen(true)}>
            <QrCodeScannerIcon />
            &nbsp;&nbsp;Scan Sensor QR
          </Button>
        )}
        {hardwareId !== '' && (
          <>
            <Box
              component='form'
              onSubmit={handleSubmit(handleSensorCreateSubmit)}
              sx={{ minWidth: '350px', maxWidth: '500px', m: 'auto' }}
              noValidate
              autoComplete='off'
            >
              <Controller
                name='hardwareId'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ mb: 2 }}
                    InputProps={{ readOnly: true }}
                    id='hardwareId'
                    label='Hardware ID'
                    error={!!errors.hardwareId}
                    helperText={errors.hardwareId ? errors.hardwareId.message : ''}
                    fullWidth
                  />
                )}
              />
              <Controller
                name='deviceName'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ mb: 2 }}
                    InputProps={{ readOnly: true }}
                    id='deviceName'
                    label='Make & Model'
                    error={!!errors.deviceName}
                    helperText={
                      errors.deviceName
                        ? errors.deviceName.message
                        : 'Capabilities: Water leaks, Doors, Temperature, Humidity'
                    }
                    fullWidth
                  />
                )}
              />
              <Box>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <FormControl fullWidth>
                      <Controller
                        name='unitId'
                        control={control}
                        render={({ field }) => (
                          <>
                            <InputLabel>Zone</InputLabel>
                            <Select
                              {...field}
                              id='unitId'
                              label='Zone'
                              variant='outlined'
                              error={!!errors.unitId}
                              fullWidth
                              sx={{
                                textAlign: 'left',
                                '& .MuiSelect-select': {
                                  alignItems: 'left'
                                }
                              }}
                            >
                              {units.map((unit, index) => (
                                <MenuItem key={index} value={unit.id}>
                                  {unit.number} - {unit.floor + getOrdinal(unit.floor)} floor
                                </MenuItem>
                              ))}
                            </Select>
                          </>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} sx={{ m: 'auto' }}>
                    <Button
                      sx={{ p: 1.5 }}
                      fullWidth
                      variant='contained'
                      color='primary'
                      onClick={() => setShowUnitModal(true)}
                    >
                      Add a Zone
                    </Button>
                  </Grid>
                </Grid>
                <FormHelperText sx={{ ml: 2, mb: 1 }} htmlFor='render-select' error={!!errors.unitId}>
                  {errors.unitId ? errors.unitId.message : 'Zone where the sensor is located'}
                </FormHelperText>
              </Box>
              <Controller
                name='location'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id='location'
                    sx={{ mb: 1 }}
                    label='Location*'
                    error={!!errors.location}
                    helperText={
                      errors.location ? errors.location.message : 'Sensor Installation Location within Zone'
                    }
                    fullWidth
                  />
                )}
              />
              <Grid container>
                <Grid item>
                  <FormControl fullWidth>
                    <Controller
                      name={'inUse'}
                      control={control}
                      render={({ field }) => (
                        <FormCheckbox
                          label={
                            <Grid container direction='row' alignItems='center'>
                              <Grid item>
                                <Typography>In Use</Typography>
                              </Grid>
                              <Grid item>
                                <TooltipIcon
                                  title={
                                    'In Use indicates that the sensor should be collecting data. If this is ' +
                                    'disabled, messages arriving from this sensor will be ignored.'
                                  }
                                  textFontSize={14}
                                />
                              </Grid>
                            </Grid>
                          }
                          checked={field.value}
                          onChange={(e) => setValue('inUse', e.target.checked)}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <Controller
                      name={'enableAlerts'}
                      control={control}
                      render={({ field }) => (
                        <FormCheckbox
                          label={
                            <Grid container direction='row' alignItems='center'>
                              <Grid item>
                                <Typography>Enable Alerts</Typography>
                              </Grid>
                              <Grid item>
                                <TooltipIcon
                                  title={
                                    'Enable Alerts controls whether alerts are being sent out for the sensor. ' +
                                    'If "In Use" is disabled, then this flag will be ignored as data will not be ' +
                                    'collected. Disabling alerts can be for sensors where we want to collect data ' +
                                    'about the sensor but not send any alerts.'
                                  }
                                  textFontSize={14}
                                />
                              </Grid>
                            </Grid>
                          }
                          disabled={!inUseEnabled}
                          checked={field.value}
                          onChange={(e) => setValue('enableAlerts', e.target.checked)}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <ConfigSlider
                control={control}
                sliderEnabled={waterEnabled}
                sliderValue={waterSensitivity}
                setSliderValue={setWaterSensitivity}
                setValue={setValue}
                min={0}
                max={60}
                marks={WATER_SENSITIVITY_SLIDER_MARKS}
                title={'Water'}
                fieldId={'monitorWater'}
                tooltip={
                  'Only send an alert if the device detects water continuously for the specified number of minutes.' +
                  ' 0 minutes is most sensitive, 60 minutes is least sensitive. This helps prevent alerts from things' +
                  ' like floors being mopped or occasional condensation drips that are expected.'
                }
              />
              <ConfigSlider
                control={control}
                sliderEnabled={tempEnabled}
                sliderValue={tempRange}
                setSliderValue={setTempRange}
                setValue={setValue}
                min={-50}
                max={100}
                marks={TEMPERATURE_SLIDER_MARKS}
                title={'Temperature'}
                fieldId={'monitorTemperature'}
                tooltip={
                  'Device will send alerts if the temperature rises or falls outside the specified range.'
                }
              />
              <ConfigSlider
                control={control}
                sliderEnabled={humidityEnabled}
                sliderValue={humidityRange}
                setSliderValue={setHumidityRange}
                setValue={setValue}
                min={0}
                max={100}
                marks={HUMIDITY_SLIDER_MARKS}
                title={'Humidity'}
                fieldId={'monitorHumidity'}
                tooltip={
                  'Device will send alerts if the humidity rises or falls outside the specified range.'
                }
              />
              <Box sx={{ pb: 5, pt: 2 }}>
                <Button type='submit' variant='contained' color='primary' style={{ marginRight: 10 }}>
                  Save Sensor
                </Button>
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={() => (window.location.href = '/sensors?building_id=' + buildingId.toString())}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </>
        )}

        <CreateUnitModal
          buildingId={buildingId}
          showUnitModal={showUnitModal}
          setShowUnitModal={setShowUnitModal}
          handleAddUnit={handleAddUnit}
        />
        <Dialog open={qrModalOpen} onClose={handleClose}>
          <DialogTitle>Sensor QR Code Scan</DialogTitle>
          <DialogContent>
            <Scanner onScan={(scanResult) => handleClose({ scanResult })} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => window.history.back()} variant='outlined' color='primary'>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  )
}

export default SensorCreatePage
